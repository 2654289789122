import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'

class DevPage extends Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = this.props.data.allMarkdownRemark.edges

    const displayPosts = posts.map((post, index) => {
      return (
        <div key={index}>
          <Link to={post.node.fields.slug}>
            <h3>{post.node.frontmatter.title}</h3>
          </Link>
        </div>
      )
    })
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Developer Environment Precourse Section" />
        <h1>Developer Environment</h1>
        <p>
          Welcome to the first section on your ascent towards DevMountain! Here
          we will be discussing setting up your developer environment. Work
          through each section which contains both video and reading material to
          help set you up for success come the first day of class.
        </p>
        {displayPosts}
      </Layout>
    )
  }
}

export default DevPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: { fileAbsolutePath: { regex: "//dev-env/" } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            order
            title
          }
        }
      }
    }
  }
`
